export const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hash = hashArray.map((item) => item.toString(16).padStart(2, '0')).join('')
  return hash
}

export function generatePassword(): string {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const passwordLength = 10
  let newPassword = ''
  for (let i = 0; i <= passwordLength; i++) {
    // eslint-disable-next-line security-node/detect-insecure-randomness
    const randomNumber = Math.floor(Math.random() * chars.length)
    newPassword += chars.substring(randomNumber, randomNumber + 1)
  }
  return newPassword
}
